import React from 'react';
import { useLocation } from '@reach/router';
import AnnouncementBarStyles from '../styles/announcementBar';

export default function AnnouncementBar() {
  const location = useLocation();

  return (
    <AnnouncementBarStyles>
      <a href={`https://beta.dutchrentalz.com${location.pathname}`}>
        <p>
          Be among the first to test out our new website! Reserve equipment
          online!
        </p>
      </a>
    </AnnouncementBarStyles>
  );
}
