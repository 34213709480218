import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { CloseBtnAlt } from '@components/announcement';
import CategorySidebarLink from '@components/categorySidebarLink';

export default function CategoryModal({ toggle }) {
  const { categories } = useStaticQuery(graphql`
    query mobileCategories {
      categories: allPrismicCategories(sort: { fields: data___name }) {
        edges {
          node {
            id
            uid
            data {
              name
            }
          }
        }
      }
    }
  `);

  return (
    <MobileNavStyles>
      <h3>Browse by category</h3>
      <Link to="/equipment" className="all">
        All Equipment
      </Link>
      <Link to="/attachments" className="all">
        All Attachments
      </Link>
      <Link to="/for-sale" className="all">
        All For Sale
      </Link>
      <div className="separator" />
      {categories.edges.map(({ node }) => (
        <CategorySidebarLink key={node.uid} node={node} slug={null}>
          {node.data.name}
        </CategorySidebarLink>
      ))}
      <CloseBtnAlt>
        <button type="button" onClick={toggle} aria-label="Close">
          Close
        </button>
      </CloseBtnAlt>
    </MobileNavStyles>
  );
}

CategoryModal.propTypes = {
  toggle: PropTypes.func.isRequired,
};

const MobileNavStyles = styled.nav`
  overflow: auto;

  .separator {
    background: ${props => props.theme.black};
    height: 0.1rem;
    margin: 1rem auto 1rem 0;
    max-width: 7.5rem;
  }

  h3 {
    padding: 1rem;
  }

  a {
    color: ${props => props.theme.black};
    display: block;
    font-size: ${props => props.theme.fs4};
    margin: 0.3rem 0;
    padding: 1rem;
    position: relative;
    text-decoration: none;
  }

  a:hover {
    background: ${props => props.theme.offwhite};
  }
`;

export const CategoryModalStyles = styled.div`
  align-items: center;
  display: flex;

  &,
  .mobile-categories-btn-wrapper,
  .mobile-categories-btn-wrapper button {
    height: 3.5rem;

    @media screen and (min-width: 768px) {
      height: auto;
    }
  }

  .mobile-categories-btn-wrapper.mobile {
    background: ${props => props.theme.white};
    border: none;
    left: 0;
    position: fixed;
    right: 0;
    top: 14rem;
    width: 100%;
    z-index: 999;

    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  .mobile-categories-btn-wrapper.desktop {
    display: none;

    @media screen and (min-width: 768px) {
      display: block;
      position: static;
      width: auto;
    }

    @media screen and (min-width: 1200px) {
      display: ${({ page }) => (page !== `single` ? `none` : `flex`)};
    }
  }

  .mobile-categories-btn-wrapper button {
    align-items: center;
    background: ${props => props.theme.white};
    border: none;
    border-bottom: 0.1rem solid ${props => props.theme.offwhite};
    border-radius: 0;
    display: flex;
    justify-content: space-between;
    outline: none;
    padding: 0rem 3rem;
    text-align: left;
    width: 100%;

    @media screen and (min-width: 768px) {
      border: none;
      color: ${props => props.theme.darkgrey};
      justify-content: flex-start;
      margin-right: 2rem;
      padding: 0 0.75rem;
      text-decoration: underline;
      white-space: nowrap;
      width: auto;
    }
  }

  svg {
    @media screen and (min-width: 768px) {
      display: none;
    }
  }
`;
