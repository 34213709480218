import styled from 'styled-components';

const AnnouncementBar = styled.div`
  background: hsl(54, 99%, 50%);
  width: 100%;
  text-align: center;

  a {
    display: block;
    color: ${props => props.theme.black};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    font-weight: 600;
    margin: 0;
    padding: 1rem 0;
  }
`;

export default AnnouncementBar;
